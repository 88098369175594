<template>
    <b-row class="match-height">
      <b-col lg="12">
        <b-card title="Purchase Request Revise Log">
          <b-row>
            <b-col cols="12">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
            </b-col>
            <b-col cols="12">
            <b-form-group
              label="Purchase Request Number"
              label-for="purchase_request_number"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_request_number"
                v-model="purchaseRequestNumber"
                placeholder="Project"
                readonly
              />
            </b-form-group>
              <b-row v-if="users.length>0">
                <b-col cols="6" class="text-center font-weight-bold">
                  User Name
                </b-col>
                <b-col cols="6" class="text-center font-weight-bold">
                  Requested Date
                </b-col>
              </b-row>
              <p v-if="users.length==0" class="centered">
                No Data
              </p>
              <p />
              <b-row v-for="user in users" :key="user.user.user_id">
                <br />
                <b-col cols="6 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                      {{ user.user.user_fullname }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="6 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                      {{ user.revise_created_time }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="12">
                  <br />
                </b-col>
              </b-row>
              </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
  } from "bootstrap-vue"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import axios from "axios"
  import moment from "moment"
  import Ripple from "vue-ripple-directive"

  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
  import { ref } from "@vue/composition-api"

  const VersionCheck = ""
  const statusItems = []
  const items = []
  const userItem = ref()
  const users = []
  const documents = []
  const purchaseRequestType = ""
  const purchaseRequestNumber = ""
  const purchaseRequestIsConfirmed = ""
  const purchaseRequestIsConfirmedByFACoalManager = ""
  const purchaseRequestClass = ""
  const purchaseRequestProject = ""
  const purchaseRequestCostCenter = ""
  const purchaseRequestQtyRequested = 0
  const purchaseRequestStatus = ""
  const purchaseRequestNotes = ""
  const purchaseRequestDeliverTo = ""
  const purchaseRequestNeedFor = ""
  const purchaseRequestCategory = ""
  const purchaseRequestAssetCategory = ""
  const userBranch = ""
  const branches = []
  const userClass = ""
  const classes = ["Urgent", "Normal"]
  const isCenter = false
  const isVisiblePrint = false
  export default {
    components: {
      BDropdownItem,
      BDropdown,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
      VueHorizontalTimeline,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        purchaseRequestDeliverTo,
        purchaseRequestNeedFor,
        purchaseRequestCategory,
        purchaseRequestAssetCategory,
        documents,
        isCenter,
        purchaseRequestIsConfirmedByFACoalManager,
        purchaseRequestType,
        purchaseRequestIsConfirmed,
        userBranch,
        branches,
        userClass,
        classes,
        statusItems,
        VersionCheck,
        items,
        userItem,
        users,
        purchaseRequestNumber,
        purchaseRequestClass,
        purchaseRequestProject,
        purchaseRequestCostCenter,
        purchaseRequestQtyRequested,
        purchaseRequestStatus,
        purchaseRequestNotes,
        isVisiblePrint,
      }
    },
    mounted() {
      this.users = []
      this.getDetails()
    },
    methods: {
      downloadAttachment(document) {
        const userToken = this.$cookies.get("userToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  }
        const FileName = document.purchase_request_document_file_name
        const ObjectName = `${document.purchase_request_document_number}${FileName}`
        const body = {
          ObjectName,
          FileName,
        }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_ASSET}`,
            body,
            { headers },
          )
          .then(response => {
            if (response.data.Status === 1) {
              window.open(response.data.Payload, "_blank")
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Download Attachment success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
      },
      getDetails() {
        const userToken = this.$cookies.get("userToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  }
        const pr = JSON.parse(localStorage.getItem("purchaseRequestDetails"))
        console.log(pr)
        const id = pr.purchase_request_id
        axios
          .get(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_REQUESTS_REVISES}/${id}`,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              const itemsJoin = []
              response.data.data.map(elem => {
                itemsJoin.push({
                  user: elem.user,
                  revise_created_time: elem.revise_created_time,
                })
              })
              this.users = itemsJoin
              this.purchaseRequestNumber = pr.purchase_request_number
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Get Branches Failed",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
          .catch(e => {
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      },
      getBranches() {
        const userToken = this.$cookies.get("userToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  }
        axios
          .get(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              const itemsJoin = []
              response.data.data.map(elem => {
                itemsJoin.push({
                  label: `${elem.branch_name} (${elem.branch_code})`,
                  value: elem.branch_id,
                })
              })
              this.branches = itemsJoin
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Get Branches Failed",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
          .catch(e => {
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Branches Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      },
      getItems() {
        const userToken = this.$cookies.get("userToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  }
        axios
          .post(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PR_PLAN}`,
            {},
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              const itemsJoin = []
              response.data.data.map(elem => {
                itemsJoin.push({
                  label: elem.item.item_name,
                  value: elem.purchase_request_detail_id,
                  qty: elem.purchase_request_detail_item_qty_requested,
                })
              })
              this.items = itemsJoin
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Get Data Success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Get Data Failed",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
          .catch(e => {
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Settings Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      },
      submitNewPurchaseRequest() {
        const userToken = this.$cookies.get("userToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  }
        this.users.map(elem => {
          this.purchaseRequestQtyRequested += parseInt(elem.qty)
        })
        const body = {
          purchase_request_class: this.userClass,
          purchase_request_project: this.purchaseRequestProject,
          purchase_request_cost_center: this.userBranch.value,
          purchase_request_notes: this.purchaseRequestNotes,
          material_request_id: this.purchaseRequestQtyRequested,
          purchase_request_items: JSON.stringify(this.users),
          time: moment(),
        }
        axios
          .post(
            "http://localhost:5000/transactions/purchase_requests/create",
            body,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              this.users = []
              this.purchaseRequestQtyRequested = 0
              this.purchaseRequestCostCenter = ""
              this.purchaseRequestProject = ""
              this.purchaseRequestClass = ""
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Purchase Request Success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              this.$router.replace({
                name: "apps-procurement-purchase-request-list",
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Purchase Request Failed",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              })
            }
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Purchase Request Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          })
      },
      getOptionLabel(option) {
        return (option && option.label) || ""
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ""
      },
      inputSearch(search) {
        console.log(search)
      },
      dateFormat(value, format = "MM/DD/YYYY") {
        return moment(String(value)).format(format)
      },
      previous() {
        this.$router.push({ name: "apps-procurement-purchase-request-list" })
      },
      dateSimple(value, format = "YYYY-MM-DD") {
        let dateRet = ""
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else {
          dateRet = null
        }
        return dateRet
      },
      capitalizeWords(str) {
      // Split the string into words using spaces as the separator
      const words = str.split(' ')
      // Capitalize the first letter of each word and join them back together
      const capitalizedWords = words.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1)
      })
      // Join the capitalized words into a single string with spaces
      return capitalizedWords.join(' ')
      },
    },
  }
  </script>
<style scoped>
.centered {
  display: flex;
  justify-content: center;  /* Centers horizontally */
  align-items: center;      /* Centers vertically */
  height: 100vh;           /* Makes the container full height */
}
</style>
